import Vacant1 from '../assets/PROJETS/01-Vacant/Vacant-01.jpg';
import Vacant2 from '../assets/PROJETS/01-Vacant/Vacant-02.jpg';
import Vacant3 from '../assets/PROJETS/01-Vacant/Vacant-03.jpg';
import Vacant4 from '../assets/PROJETS/01-Vacant/Vacant-04.jpg';
import Vacant5 from '../assets/PROJETS/01-Vacant/Vacant-05.jpg';
import Bova1 from '../assets/PROJETS/02-Bovary/Bovary_01.jpg';
import Bova2 from '../assets/PROJETS/02-Bovary/Bovary_02.jpg';
import Bova3 from '../assets/PROJETS/02-Bovary/Bovary_03.jpg';
import Bova4 from '../assets/PROJETS/02-Bovary/Bovary_04.jpg';
import Tdtf1 from '../assets/PROJETS/03-Too dumb to fail/Too_dumb_to_fail-01.jpg';
import Tdtf2 from '../assets/PROJETS/03-Too dumb to fail/Too_dumb_to_fail-02.jpg';
import Tdtf3 from '../assets/PROJETS/03-Too dumb to fail/Too_dumb_to_fail-03.jpg';
import LOL1 from '../assets/PROJETS/04-Labor0Labor/L0L_01.jpg';
import LOL2 from '../assets/PROJETS/04-Labor0Labor/L0L_02.jpg';
import LOL3 from '../assets/PROJETS/04-Labor0Labor/L0L_03.jpg';
import LOL4 from '../assets/PROJETS/04-Labor0Labor/L0L_04.jpg';
import LOL5 from '../assets/PROJETS/04-Labor0Labor/L0L_05.jpg';
import LOL6 from '../assets/PROJETS/04-Labor0Labor/L0L_06.jpg';
import Icfvp1 from '../assets/PROJETS/05-Irony Comes from a Vanishing Point/Irony_01.jpg';
import Icfvp2 from '../assets/PROJETS/05-Irony Comes from a Vanishing Point/Irony_02.jpg';
import Icfvp3 from '../assets/PROJETS/05-Irony Comes from a Vanishing Point/Irony_03.jpg';
import Incr1 from '../assets/PROJETS/06-Incremental_Self/Incremental_self-01.jpg';
import Incr2 from '../assets/PROJETS/06-Incremental_Self/Incremental_self-02.jpg';
import Incr3 from '../assets/PROJETS/06-Incremental_Self/Incremental_self-03.jpg';
import Incr4 from '../assets/PROJETS/06-Incremental_Self/Incremental_self-04.jpg';
import Rtah1 from "../assets/PROJETS/07-Are_you_Ready_to_Try_the_Artists_Habits/AYRTAH-01.jpg";
import Rtah2 from "../assets/PROJETS/07-Are_you_Ready_to_Try_the_Artists_Habits/AYRTAH-02.jpg";
import Rtah3 from "../assets/PROJETS/07-Are_you_Ready_to_Try_the_Artists_Habits/AYRTAH-03.jpg";
import Rtah4 from "../assets/PROJETS/07-Are_you_Ready_to_Try_the_Artists_Habits/AYRTAH-04.jpg";
import Obs1 from "../assets/PROJETS/08-Obstruction/Obstruction_01.jpg";
import Obs2 from "../assets/PROJETS/08-Obstruction/Obstruction_02.jpg";
import Obs3 from "../assets/PROJETS/08-Obstruction/Obstruction_03.jpg";
// import Newk2B from "../assets/PROJETS/07-NEW KID/NEW_KID-02-Before.jpg";
// import Newk2A from "../assets/PROJETS/07-NEW KID/NEW_KID-02-After.jpg";
import Newk1 from "../assets/PROJETS/09-NEW KID/NEW_KID-01.jpg";
import Newk2 from "../assets/PROJETS/09-NEW KID/NEW_KID-02.jpg";
import Newk3 from "../assets/PROJETS/09-NEW KID/NEW_KID-03.jpg";
import Newk4 from "../assets/PROJETS/09-NEW KID/NEW_KID-04.jpg";
import Newk5 from "../assets/PROJETS/09-NEW KID/NEW_KID-05.jpg";
import Newk6 from "../assets/PROJETS/09-NEW KID/NEW_KID-06.jpg";
import Newk7 from "../assets/PROJETS/09-NEW KID/NEW_KID-07.jpg";
import Newk8 from "../assets/PROJETS/09-NEW KID/NEW_KID-08.jpg";
import Newk9 from "../assets/PROJETS/09-NEW KID/NEW_KID-09.jpg";
import Newk10 from "../assets/PROJETS/09-NEW KID/NEW_KID-10.jpg";
import Newk11 from "../assets/PROJETS/09-NEW KID/NEW_KID-11.jpg";
import Pyr1 from "../assets/PROJETS/10-PYR/PYR_01.jpg";
import Pyr2 from "../assets/PROJETS/10-PYR/PYR_02.jpg";
import Pyr3 from "../assets/PROJETS/10-PYR/PYR_03.jpg";
import Pyr4 from "../assets/PROJETS/10-PYR/PYR_04.jpg";
import Pyr5 from "../assets/PROJETS/10-PYR/PYR_05.jpg";
import Gnat1 from "../assets/PROJETS/11-Grandeur Nature/grandeur_nature-01.jpg";
import Gnat2 from "../assets/PROJETS/11-Grandeur Nature/grandeur_nature-02.jpg";
import Gnat3 from "../assets/PROJETS/11-Grandeur Nature/grandeur_nature-03.jpg";
import Gnat4 from "../assets/PROJETS/11-Grandeur Nature/grandeur_nature-04.jpg";
import Gnat5 from "../assets/PROJETS/11-Grandeur Nature/grandeur_nature-05.jpg";
import Gnat6 from "../assets/PROJETS/11-Grandeur Nature/grandeur_nature-06.jpg";
import Deconf1 from "../assets/PROJETS/12-La_Déconfiture/Deconfiture-01.jpg";
import Deconf2 from "../assets/PROJETS/12-La_Déconfiture/Deconfiture-02.jpg";
import Deconf3 from "../assets/PROJETS/12-La_Déconfiture/Deconfiture-03.jpg";
import Deconf4 from "../assets/PROJETS/12-La_Déconfiture/Deconfiture-04.jpg";
import Deconf5 from "../assets/PROJETS/12-La_Déconfiture/Deconfiture-05.jpg";


export const projetsList = [
    {
        id: 'DECONF',
        artist: 'Emilie Moutsis',
        name: 'La Deconfiture',
        work: 'Etalonnage',
        duration: '27 min',
        pic: [Deconf1, Deconf2, Deconf3, Deconf4, Deconf5],
        description: `Émilie Moutsis se met à la recherche d'une preuve accablante de sa part de responsabilité dans nos 
        catastrophes actuelles. Elle plonge dans ses archives. Des bribes de film 8 mm interpellent la réalisatrice. 
        Sur ces images intimes en noir et blanc, datant de 1950, s'inscrivent les indices d'un changement de paradigme.`
    },
    {
        id: 'GNAT',
        artist: 'Arnaud Dezoteux',
        name: 'Grandeur Nature',
        work: 'Etalonnage',
        duration: '37 min',
        pic: [Gnat1, Gnat2, Gnat3, Gnat4, Gnat5, Gnat6],
        synopsis: `Nuria Delormes, jeune anarchiste bannie d'Aquilonie pour appel à la sédition, traverse les contrées 
        hyboréennes afin de comprendre lequel des systèmes politique et social qui composent le monde connu est le 
        plus juste. On aperçoit circulant autour d'elle les membres de son clan de chroniqueurs, Litus Frabis, 
        jeune monte-en-l'air intrépide et gouailleur pourvoyant à la pitance de la bande par le biais de larcins divers, 
        combines et astuces de son invention, ainsi que Celsian Déésprès, artisan cornetier spécialisé dans la 
        fabrication de porte-voix utiles à la réclame mais surtout de prothèses amplificatrices lui permettant 
        de décupler ses facultés auditives et de saisir en toute discrétion les conversation hors de portée. 
        Enfin, celui qui reste hors-champ puisque occupé à croquer les scènes, Deuzic de Landrin, perspectiviste en 
        rupture familiale, s'accroche à sa boite noire qui lui permet de dessiner avec précision les territoires 
        traversés afin de nous en restituer les plus exactes impressions.`
    },
    {
        id: 'PYR',
        artist: 'Isis, Elsa et Marius LHERM',
        name: 'PYR',
        work: 'Etalonnage',
        duration: '43 min',
        pic: [Pyr1, Pyr2, Pyr3, Pyr4, Pyr5],
        synopsis: `Sur le flanc d'une montagne, dans une zone reculée et désertée par les humains depuis un temps 
        inconnu, un groupe de personnes habillées de blanc se déplace pour accomplir une mystérieuse mission. 
        PYR est le titre du premier film réalisé par Isis et Elsa Lherm. Imaginées en plein confinement en mars 2020, 
        les images de cette communauté étrange ont été tournées l'été suivant dans la région des Pyrénées. 
        PYR parle de mémoire, des traces invisibles que laissent les disparitions, les blessures et les feux, à travers le temps.`
    },
    {
        id: 'TNK1',
        artist: 'Arnaud Dezoteux',
        name: 'The New Kid',
        work: 'Etalonnage',
        duration: '72 min',
        pic: [Newk6, Newk2, Newk3, Newk4, Newk5, Newk1, Newk7, Newk8, Newk9, Newk10, Newk11],
        // slider: [Newk2B, Newk2A],
        synopsis: `Dans ce show local et largement improvisé, les habitants de la ville de Las Vegas, sont invités à 
        témoigner sur leur connaissance du personnage et sur la projection de Billy The Kid qu'ils envisageraient 
        aujourd'hui. Pourrait-il encore exister au 21e siècle ?`
    },
    {
        id: 'OBS',
        artist: 'Liv SCHULMAN',
        name: "L'Obstruction",
        work: 'Etalonnage',
        duration: '26 min',
        pic: [Obs1, Obs2, Obs3],
        synopsis: `Dans son film L'Obstruction, Liv Schulman met en scène un jeune homme balbutiant et transpirant,
        qui tente tant bien que mal de raconter ses histoires. Baristas, industrie pharmaceutique, fourmis,
        poils, objets vibrants et dents tatouées en sont quelques protagonistes. Souffrant de la chaleur, du
        bruit ambiant et de la proximité envahissante de corps de pierre monumentaux, l'homme tente
        d'évaluer l'effet de ses paroles sur des spectateurs-acteurs silencieux et apparemment indifférents.
        Obsédé par sa capacité à nous convaincre, nous séduire ou nous faire rire, l'impuissance et l'anxiété -
        symptomatique de notre époque - sont ici tournées en dérision.`
    },
    {
        id: 'RTAH',
        artist: 'Emmanuelle Lainé & Benjamin Valenza',
        name: "Ready to Try the Artist's Habits?",
        work: 'Etalonnage',
        duration: '19 min',
        pic: [Rtah1, Rtah2, Rtah3, Rtah4],
        synopsis: `A woman presents a narrative combined the individual accounts (fragments of interviews) by lip-synching with the original narrators in different vocal ranges. 
        This commanding woman is an allegory of this art institution, even of all art institutions. She's a mutating fictional body, a female body becoming something that defies definition. 
        An allegory, and an unstable one.`
    },
    {
        id: 'INCR',
        artist: 'Emmanuelle Lainé',
        name: "Incremental Self",
        work: 'Etalonnage',
        duration: '18 min',
        pic: [Incr1, Incr2, Incr3, Incr4],
        description: `Bétonsalon - Centre d'art et de recherche présente une exposition personnelle d'Emmanuelle Lainé. 
        Avec Incremental Self, elle s'empare des espaces remis à neuf de Bétonsalon avec une installation monumentale, 
        mêlant un film déployé sur plusieurs écrans à une accumulation de meubles et d'objets détournés de leur contexte.`
    },
    {
        id: 'ICFVP',
        artist: 'Emmanuelle Lainé',
        name: 'Irony Comes from a Vanishing Point',
        work: 'Infographie 3D',
        duration: '4min09',
        pic: [Icfvp1, Icfvp2, Icfvp3],
        description: `Irony Comes From a Vanishing Point,
        Steve Bishop,
        Stéphane Barbier Bouvet,
        Emmanuelle Lainé,
        Bea Schlingelhoff,
        Curated by Julia Marchand,
        galeria stereo,
        Warsaw`
    },
    {
        id: 'LOL',
        artist: 'L0L TV',
        name: 'LABOR ZERO LABOR',
        work: 'Montage / Régie',
        duration: '∞',
        pic: [LOL1, LOL2, LOL3, LOL4, LOL5, LOL6],
        synopsis: `LABOR ZERO LABOR, projet et programmation transmedia en direct conçus par Benjamin Valenza en collaboration avec Triangle France.`
    },
    {
        id: 'TDTF',
        artist: 'Hoël Duret',
        name: 'Too dumb to fail',
        work: 'Infographie 3D',
        duration: '∞',
        pic: [Tdtf1, Tdtf2, Tdtf3],
        synopsis: `L'exposition « Too Dumb To Fail » est une immersion en volume dans le nouveau scénario développé par Hoël Duret`
    },
    {
        id: 'BOVA',
        artist: 'Roxane VERMIS',
        name: "Bovary Temporary",
        work: 'VFX',
        duration: '∞',
        pic: [Bova1, Bova2, Bova3, Bova4],
        synopsis: ``
    },
    {
        id: 'VACANT',
        artist: 'Geörgette Power',
        name: "Vacant",
        work: 'Infographie 3D',
        duration: '12min',
        pic: [Vacant1, Vacant2, Vacant3, Vacant4, Vacant5],
        synopsis: `Installation vidéo de Geörgette Power présentée au Beirut Art Center lors de l'exposition « Under construction »`
    },
]

