import '../styles/Homepage.css';
import HMP1 from '../assets/HomeBG/Acceuil_01.jpg';
import HMP2 from '../assets/HomeBG/Acceuil_02.jpg';
import MiniLogo1 from '../assets/LOGO/logo_bottom_blender.png';
import MiniLogo2 from '../assets/LOGO/logo_bottom_davinci.png';
import MiniLogo3 from '../assets/LOGO/logo_bottom_reaper.png';
import MiniLogo4 from '../assets/LOGO/logo_bottom_Fusion.png';

function Homepage() {
    return (
        <>
        <div className="img-home-wrapper">
            <img src={HMP1} alt="espace de travail" className="img1-home"/>
            <img src={HMP2} alt="logiciels" className="img2-home"/>
        </div>
        <div className='home-ctn'>
            <img src={HMP1} alt="espace de travail" className="img1-home mobile-img"/>
            <img src={HMP2} alt="logiciels" className="img2-home mobile-img"/> 
            <div className="home-text-ctn">
                <div className='home-para'>
                    <div className='h1-home-wrapper'>
                        <h1 className='h1-home'>
                            Etalonnage colorimétrique / VFX
                        </h1>
                        <div className='inter-line color-line'></div>
                    </div>
                    <h2 className='h2-home'>
                        Travail de la colorimétrie et effets spéciaux sur des films de fictions, documentaires et clips vidéo.
                    </h2>
                    <p className='text-home'>
                        Je pratique la vidéo, principalement la post-production depuis 15 ans.<br></br>
                        J'ai occupé les postes de monteur sur des projets de fiction et de régie Live TV,<br></br>
                        Effets spéciaux compositing 2D et 3D,<br></br>
                        Décoration, assistant caméra puis électro sur des tournages de fiction.<br></br>
                        <br></br>
                        J'enseigne en parallèle la technique vidéo à l'école supérieur des Beaux-Arts de Toulouse (Isdat). <br></br>
                    </p>
                </div>  
            </div>
            <div className='inter-line'></div>
            <div className="mini-logo-ctn">
                <img src={MiniLogo1} alt="logo Blender" className="mini-logo"/>
                <img src={MiniLogo2} alt="logo Davinci" className="mini-logo"/>
                <img src={MiniLogo3} alt="logo Reaper" className="mini-logo"/>
                <img src={MiniLogo4} alt="logo Fusion" className="mini-logo"/>
            </div>
        </div>
        </> 
    );
  }
  
  export default Homepage;