import '../styles/App.css';
import {Routes, Route} from "react-router-dom";
import Header from './Header';
import Homepage from './Homepage';
import Projetspage from './Projetspage';
import Contactpage from './Contactpage';
import NotFound from './NotFound';

function App() {
  return (
    <div className='main-ctn'>
      <Header/>
      <div className="main-page-ctn">
        <Routes>
            <Route path="/" element={<Homepage />}/>
            <Route path="/projets" element={<Projetspage />}/>
            <Route path="/contact" element={<Contactpage />}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>

    </div>
  );
}

export default App;
