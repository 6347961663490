import { projetsList } from '../data/projetsList';
import '../styles/Projetspage.css';
import SectionProjets from './SectionProjets';

function Projetspage() {
    return (
        <div className='projets-ctn'>
            {projetsList.map(({id, artist, name, work, duration, pic, slider, synopsis, description}) => (
                <SectionProjets
                    id={id} 
                    artist={artist}
                    name={name}
                    work={work}
                    duration={duration}
                    pic={pic}
                    slider={slider}
                    synopsis={synopsis}
                    description={description}
                />
            ))}
            
        </div>
    );
  }
  
  export default Projetspage;