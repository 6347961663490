import {useRef} from "react";
import {Link} from "react-router-dom";
import '../styles/Header.css';

function Header() {

    const navRef = useRef();
    const showNavBar = ()=> {
      navRef.current.classList.toggle("responsive-nav")
    }
    const hideNavBar = ()=> {
      navRef.current.classList.remove("responsive-nav")
    }

    return (
      <header className='header-ctn'>
        <div className='header-wrapper'>
          <div className='logo-ctn'>
            <h2 className='title'>LECOINTRE LÉNY &nbsp;</h2>
            <h2 className='title-clone clone3'>Y &nbsp;</h2>
            <h2 className='title-clone clone2'>Y &nbsp;</h2>
            <h2 className='title-clone clone1'>Y &nbsp;</h2>
          </div>
          <div>
            <nav className='navbar-ctn' ref={navRef}>
              <Link onClick={hideNavBar} to="/" className='link'>Home</Link>
              <div className="red-border"></div>
              <Link onClick={hideNavBar} to="/projets" className='link'>Projets</Link>
              <div className="red-border"></div>
              <Link onClick={hideNavBar} to="/contact" className='link'>Contact</Link>
              <button className="nav-btn nav-close-btn" onClick={showNavBar}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFFFFF" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
              </button>
            </nav>
            <button className="nav-btn" onClick={showNavBar}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFFFFF" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path></svg>
              </button>
          </div>
        </div>
      </header>
    );
  }
  
  export default Header;