import React from 'react'
import '../styles/NotFound.css';

function NotFound() {
  return (
    <div className='notfound-ctn'>
        <div className="notfound-wrapper">
            <h2 className="h2-notfound">Page Introuvable</h2>
            <p className='text-notfound'>Veuillez selectionner une autre page</p>
        </div>
    </div>
  )
}

export default NotFound;
