import '../styles/Carousel.css';
import React, {useState} from 'react'

function Carousel({pic, name}) {
    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === pic.length - 1 ? 0 : slide + 1);
    }
    const prevSlide = () => {
        setSlide(slide === 0 ? pic.length - 1 : slide - 1);
    }

  return (
    <>
      <div className="carousel-ctn">
          <button className="carousel-btn btn-prev" onClick={prevSlide}>
            <svg className="carousel-svg-btn" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,72H128V32a8,8,0,0,0-13.66-5.66l-96,96a8,8,0,0,0,0,11.32l96,96A8,8,0,0,0,128,224V184h80a16,16,0,0,0,16-16V88A16,16,0,0,0,208,72Zm0,96H120a8,8,0,0,0-8,8v28.69L35.31,128,112,51.31V80a8,8,0,0,0,8,8h88Z"></path></svg>
          </button>
          {pic.map((imgSlider, index) =>
            <li className="carousel-slide" key={index}>
              <img src={imgSlider} alt={name} className={slide === index ? "slide" : "slide slide-hidden"}/>
            </li>
          )}
          <button className="carousel-btn btn-next" onClick={nextSlide}>
            <svg className="carousel-svg-btn" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M237.66,122.34l-96-96A8,8,0,0,0,128,32V72H48A16,16,0,0,0,32,88v80a16,16,0,0,0,16,16h80v40a8,8,0,0,0,13.66,5.66l96-96A8,8,0,0,0,237.66,122.34ZM144,204.69V176a8,8,0,0,0-8-8H48V88h88a8,8,0,0,0,8-8V51.31L220.69,128Z"></path></svg>
          </button>
      </div> 
    </>
  )
}

export default Carousel;
