import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/Contactpage.css';
import LogoFace from '../assets/LOGO/photo_profil_01_circle.png';

function Contactpage() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_79xuvyl', 'template_jkx7nk5', form.current, '4jmm6mTJlSeoqVE6Z')
        .then((result) => {
            // console.log(result.text);
            alert("Le message a bien été envoyé");
            e.target.reset();
        }, (error) => {
            // console.log(error.text);
            alert("Le message n'a pas pu être envoyé");
        });
    };
  
    return (
        <div className='contact-ctn'>
            <div className="contact-wrapper">
            <div className='contact-info-ctn'>
                <img src={LogoFace} alt="Leny Lecointre" className='contact-port'/>
                <div className='contact-info'>
                    <h2 className='h2-contact'>ME CONTACTER</h2>
                    <p className='para-contact'>
                        Pour toutes demandes d'informations, <br></br>
                        CV, dossier artistique et autre
                    </p>
                </div>
            </div>
            <div className='contact-form-ctn'>
                <form ref={form} onSubmit={sendEmail} className='contact-form'>
                    <label className='contact-form-labels'>
                        <svg className="label-colors" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#6CD4FF" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>
                        <h3 className="label-name">Nom</h3>
                    </label>
                    <input type="text" name="user_name" className='input-form-contact'/>
                    <label className='contact-form-labels'>
                        <svg className="label-colors" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#E5C334" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>
                        <h3 className="label-name">Email</h3>
                    </label>
                    <input type="email" name="user_email" className='input-form-contact'/>
                    <label className='contact-form-labels'>
                        <svg className="label-colors" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#DF4D29" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>
                        <h3 className="label-name">Message</h3>
                    </label>
                    <textarea name="message" className='contact-message input-form-contact'/>
                    <button type="submit" className='contact-send-btn'>
                        <p className='text-btn'>Envoyer le message</p>   
                    </button>
                </form>
            </div>
            </div>
        </div>
    );
  }
  
  export default Contactpage;