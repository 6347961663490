import '../styles/SectionProjets.css';
import Carousel from './Carousel';
import Slider from './Slider';

function SectionProjets({id, artist, name, work, duration, pic, slider, synopsis, description}) {

  return (
    <li key={id} className="article-ctn">
        <div className='inter-line'></div>
        <div className="section-header">
            <h4 className='h4-projets'>{work}</h4>
            <div className='project-title-wrapper'>
              <h2 className='project-title project-artist'>{artist}</h2>
              <h2 className='project-title project-spliter'>&nbsp; - &nbsp;</h2>
              <h2 className='project-title project-name'>{name}</h2>
            </div>
            <p className='duration'>{duration}</p>
        </div>
        <div className="section-pic">
            {slider && (<Slider slider={slider} name={name}/>)}
            {pic.length === 1 && (<img src={pic} alt={name} className='img-fix'/>)}
            {pic.length > 1 && (<Carousel pic={pic} name={name}/>)}
        </div>
        <div className="section-para">
          {synopsis ? 
            <><div className="section-text">
              <h3 className='h3-projets'>Synopsis</h3>
              <div className='inter-line color-line-blue cl-s'></div>
              <p className='content-para'>{synopsis}</p>
            </div></>
            :  <></>}
          {description ? 
            <><div className="section-text">
              <h3 className='h3-projets'>Description</h3>
              <div className='inter-line color-line-blue cl-d'></div>
              <p className='content-para'>{description}</p> 
            </div></>
            : <></> }
        </div>
    </li>
  )
}

export default SectionProjets;

